import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import { withStyles, ButtonBase, Grid } from "@material-ui/core"

const style = theme => ({
  previewButton: {
    display: "inline-block",
    background: "transparent",
    border: "none",
    padding: 0,
    margin: 0,
    width: '100%',
    height: 243,
    borderRadius: 20,
    backgroundSize: 'cover!important'
  }
})

const ThumbGrid = ({ images, handleOpen, classes }) => {
  return images.map((image, i) => (
    <Grid item xs={12} key={i} style={i != 0 ? {display: 'none'} : null}>
      <ButtonBase
        onClick={handleOpen(i)}
        className={classes.previewButton}
        style={{background: `url(${image}) center center no-repeat`}}
        />
    </Grid>
  ))
}

ThumbGrid.propTypes = {
  classes: PropTypes.object,
  images: PropTypes.array,
}
export default withStyles(style)(ThumbGrid)
