import React, {useState} from "react"
import Layout from '../components/Layout'

import ModalVideo from 'react-modal-video'
import './../../node_modules/react-modal-video/scss/modal-video.scss';
// import './../../node_modules'


import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import YouTubeIcon from '@material-ui/icons/YouTube';
import MicIcon from '@material-ui/icons/Mic';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';

import {InlineShareButtons} from 'sharethis-reactjs';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/src/styles.scss'

import Gallery from '../components/Gallery';
import Relatos from './../components/Relatos';

// import Seo from './../components/Seo'

const Page = ({data}) => {
  const [isOpen, setOpen] = useState(false)
  const [isOpenRel, setOpenRel] = useState(false)
  let { title, body, field_files, field_galeria_articulos, field_player_articulos, field_video_embed_url } = data.sitePage.context;
  var idYoutube = null;
  if (field_video_embed_url && field_video_embed_url.split('/embed/').length >= 2 && field_video_embed_url.split('/embed/')[1]) {
    idYoutube = field_video_embed_url.split('/embed/')[1];
  }

  if (!data || !data.sitePage || !data.sitePage.context) {
      return (<div>Cargando</div>)
  }
  
  return (
    <>
      {/* <Seo title="Eventos" /> */}
      <Layout headerVideo={false} type={"chapter"} title={title}>
        <Container maxWidth={'lg'}>
          <Grid container spacing={4} alignItems={'center'}>
            <Grid item xs={12} sm={7}>
              <div class={"chapter-left"}>
                <div class='content'>
                  <div class={"title"}>
                    <div class={"iconW"}>
                      <img src={require('../images/logo.svg')} width={50} alt={'Campesinos de tierra y mar symbol'} />
                    </div>
                    <h1>{title}</h1>
                  </div>
                  {body && (
                    <div class={"desc"} dangerouslySetInnerHTML={{ __html: body }} />
                  )}
                  <div class='bottom'>
                    <div class='share-text'>
                      <ShareIcon />
                      <h3>Compartir</h3>
                    </div>
                    <div class='share-buttons'>
                      <InlineShareButtons
                        config={{
                          alignment: 'center',  // alignment of buttons (left, center, right)
                          color: 'social',      // set the color of buttons (social, white)
                          enabled: true,        // show/hide buttons (true, false)
                          font_size: 16,        // font size for the buttons
                          labels: 'null',        // button labels (cta, counts, null)
                          language: 'es',       // which language to use (see LANGUAGES)
                          networks: [           // which networks to include (see SHARING NETWORKS)
                            'facebook',
                            'twitter'
                          ],
                          padding: 12,          // padding within buttons (INTEGER)
                          radius: 4,            // the corner radius on each button (INTEGER)
                          show_total: false,
                          size: 30,             // the size of each button (INTEGER)
              
                          // OPTIONAL PARAMETERS
                          url: 'https://rtvc.gov.co', // (defaults to current url)
                          image: 'https://bit.ly/2CMhCMC',  // (defaults to og:image or twitter:image)
                          description: 'Campesinos de tierra y mar en pandemia',       // (defaults to og:description or twitter:description)
                          title: title,            // (defaults to og:title or twitter:title)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={5} align={'center'}>
              <div class={"chapter-right"}>
                {field_player_articulos && (
                  <div class={"audio"}>
                    <AudioPlayer
                      autoPlay={false}
                      src={field_player_articulos}
                      onPlay={e => {}}
                    />
                  </div>
                )}
                <Grid container spacing={5} align={'center'} justify={'center'}>
                  {idYoutube && (
                    <Grid item xs={12} lg={6}>
                        <Button onClick={() => setOpen(true)} class={'youtube-card'} style={{background: `url(https://img.youtube.com/vi/${idYoutube}/0.jpg) center center no-repeat`}}>
                          <div class='youtubeIconWrapper floatingCardBottom'>
                            <span>Video</span>
                            <YouTubeIcon />
                          </div>
                        </Button>
                        <ModalVideo channel='youtube' autoplay={false} isOpen={isOpen} videoId={idYoutube} onClose={() => setOpen(false)} />
                    </Grid>
                  )}
                  {field_galeria_articulos && (
                    <Grid item xs={12} lg={6}>
                      <Gallery items={field_galeria_articulos} />
                    </Grid>
                  )}
                  <Grid item xs={12} lg={6}>
                    <Button onClick={() => setOpenRel(true)} class={'relatos-card'} style={{background: `url(${require('../images/relatos.jpg')}) center center no-repeat`}}>
                      <div class='floatingCardBottom relatosCardBottom'>
                        <span>Relatos</span>
                        <MicIcon />
                      </div>
                    </Button>
                    <Modal
                      open={isOpenRel}
                      onClose={() => setOpenRel(false)}
                      class='relatos-modal'
                    >
                      <div class='relatos-container'>
                        <Container maxWidth={'md'}>
                          <Relatos items={field_files} />
                        </Container>
                         <IconButton aria-label="close" onClick={() => setOpenRel(false)} class={'close-modal'}>
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </Modal>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Layout>
    </>
  )
}

export default Page

export const query = graphql`
  query($slug: String!) {
    sitePage(context: {slug: {eq: $slug}}) {
        id
        context {
            title
            slug
            body
            field_files
            field_galeria_articulos
            field_player_articulos
            field_video_embed_url
        }
    }
  }
`