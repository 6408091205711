import React, { useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import ThumbGrid from "./thumbnails"
import LightBox from "./lightbox"

import Grid from "@material-ui/core/Grid"
import CameraAltIcon from '@material-ui/icons/CameraAlt';

const GalleryComponent = props => {
  var {items} = props;
  const [showLightbox, setShowLightbox] = useState(false)
  const [selectedImage, setSelectedImage] = useState(null)

  if (items) {
    items = items.split(",");
  }

  const handleOpen = i => e => {
    setShowLightbox(true)
    setSelectedImage(i)
  }
  const handleClose = () => {
    setShowLightbox(false)
    setSelectedImage(null)
  }
  const handlePrevRequest = (i, length) => e => {
    setSelectedImage((i - 1 + length) % length)
  }
  const handleNextRequest = (i, length) => e => {
    setSelectedImage((i + 1) % length)
  }
  
  return (
    <Grid container spacing={24} justify="center" style={{maxWidth: 243, position: 'relative'}}>
      <ThumbGrid images={items} handleOpen={handleOpen} />
      {showLightbox && selectedImage !== null && (
        <LightBox
          images={items}
          handleClose={handleClose}
          handleNextRequest={handleNextRequest}
          handlePrevRequest={handlePrevRequest}
          selectedImage={selectedImage}
        />
      )}
      <div class='floatingCardBottom videoCardBottom'>
        <span>Galería</span>
        <CameraAltIcon />
      </div>
    </Grid>
  )
}
export default GalleryComponent
