import React from 'react'

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import AudioPlayer from 'react-h5-audio-player';

const Component = ({items}) => {

    const [parts, setParts] = React.useState(null);

    React.useEffect(() => {
        validateArray()
    }, [])

    function validateArray() {
        var data = [];
        if (items) {    
            var generalParts = items.split(',');
            var initial = 0;
            var count = 0;
            generalParts.map((item, index) => {
                if ((index + 1) % 2 === 0) {
                    data.push({
                        part1: generalParts[index-1].split('|'),
                        part2: item.split('|'),
                    })
                }
            })
        }
        // console.log(data, "DATA");
        setParts(data);
    }

    if (!parts) {
        return <React.Fragment />
    }
    return (
        <Grid container spacing={4} alignItems={'center'} justify={'center'}>
            {
                parts.map((item, index) => {
                    var audioChecker = false;
                    if (item.part2 && item.part2[0] && item.part2[0].substr(-4) === '.mp3') {
                        audioChecker = true;
                    }
                    var loopChecker = false;
                    if (item.part1 && item.part1[0] && item.part1[0].substr(-4) === '.mp4') {
                        loopChecker = true;
                    }

                    if (!audioChecker || !loopChecker) {
                        return <React.Fragment />
                    }

                    return (
                        <Grid item xs={6} sm={4}>
                            <div class="title-relato">
                                <h2>{(item.part1 && item.part1[1]) ? item.part1[1] : '- Relato -' }</h2>
                            </div>
                            <video class="video-relato" autoPlay={true} muted loop>
                                <source src={item.part1[0]} type="video/mp4" />
                            </video>
                            <div class="audio-relato">
                                <AudioPlayer
                                    autoPlay={false}
                                    src={item.part2[0]}
                                    onPlay={e => {}}
                                />
                            </div>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

export default Component